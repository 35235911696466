<template>
  <div id="app">
    <img alt="Vue logo" src="https://cdn.siriushd.net/静的/シリウスロゴ.webp">
    <HelloWorld msg="このウェブサイトは現在作成中です。"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
body {
  font-family: 'Roboto', sans-serif;
  background-color: #000;
  color: #fff;
}
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #000;
  color: #fff;
  margin-top: 60px;
}
</style>
