<template>
  <div class="hello">
    <h1 class="name">シリウスHD</h1>
    <h2>{{ msg }}</h2>
    <p>All good things come to those who wait.</p>
    <p class="hiden" @click="rick">
      and i'm super duper lazy. so be pathient vvvv
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    rick () {
      var audio = new Audio('https://cdn.nishikino.xyz/asthriona/blog/static/gawr-gura-a.wav')
      audio.play()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  margin: 40px 0 0;
}
.hiden {
  color: black;
}
</style>
